<template>
  <div class="login d-flex justify-content-center">
    <div class="card">
      <div class="card-body p-5">
        <div class="pic text-center mb-3">
          <nuxt-img class="logo" src="/img/logos/logo-ferron.png" alt="logo-ferron.png"></nuxt-img>
        </div>
<!--        <h5 class="card-title text-center uppercase mb-4">Autopartsbox</h5>-->
        <form action="/login" method="post" @submit.prevent="validForm()">
          <h6 class="card-subtitle mb-2 text-muted">Utilisateur :</h6>
          <input class="mb-4" type="text" name="username" id="username" v-model="username" placeholder="mon compte utilisateur...">
          <h6 class="card-subtitle mb-2 text-muted">Mot de passe :</h6>
          <input class="mb-2" type="password" name="password" id="password" v-model="password" placeholder="mon mot de passe...">
          <div class="error" v-show="showEmptyField">Les identifiants de connexion doivent être renseignés.</div>
          <div class="error" v-if="showWrongField">Identifiant / Mot de passe incorrect</div>
          <div class="error" v-if="showTokenField">Serveur Inaccessible - Contactez Support SVP !</div>
          <input type="submit" class="mt-4 btn-linear w-100" value="Connexion">
        </form>
        <div class="formLastInfo d-flex justify-content-between mt-2">
          <div class="d-flex align-items-center column-gap-2">
            <span>
              <input type="checkbox" name="chkRemember" id="chkRemember">
            </span>
            <span>Se souvenir de moi</span>
          </div>
          <div class="d-flex align-items-center column-gap-2">
            <i class="fa-solid fa-circle-question"></i>
            <span>J'ai oublié mon mot de passe...</span>
          </div>
        </div>
      </div>
      <div class="card-footer d-flex justify-content-between align-items-center">
        <div class="year">
          © {{ currentYear }}
        </div>
        <div>
          <a href="https://www.graphibox.biz/nos-box#autoparts" target="_blank">
            <nuxt-img class="logoAutoPP" src="/img/logos/AutoPP-logo.png"></nuxt-img>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useAppStore } from "@/store/appStore.js";
import { useThemeColor } from "@/composables/useThemeColor.js";
const appStore = useAppStore();
const currentYear = (new Date()).getFullYear()
const username = ref("")
const password = ref("")
const showEmptyField = ref(false)
const showWrongField = ref(false)
const showTokenField = ref(false)

const validForm = async () => {
  showWrongField.value = false
  showTokenField.value = false

  if(username.value === '' || password.value === '') {
    showEmptyField.value = true
    return false
  }
  showEmptyField.value = false

  const requestLogin = await getLogin(username.value, password.value)

  if(requestLogin.value === 401) {
    showWrongField.value = true
  } else if (requestLogin.value === 500) {
    showTokenField.value = true
  } else {
    await useAsyncData('cookieAuthUser', () => $fetch('/api/writeCookies', {
      method: 'POST',
      body: {
        key: 'APP_AUTH_USER_TOKEN',
        value: requestLogin.value.token
      }
    }))
    await useAsyncData('cookieAuthUser', () => $fetch('/api/writeCookies', {
      method: 'POST',
      body: {
        key: 'APP_AUTH_DATE_END_USER_TOKEN',
        value: requestLogin.value.dateEnd
      }
    }))
    appStore.auth.userToken = requestLogin.value.token
    appStore.auth.dateEndUserToken = requestLogin.value.dateEnd
    navigateTo("/tableau-de-bord")
  }
}

const getLogin = async (username, password) => {
  const body = {
    login: username,
    password: password
  }
  
  const req = await useAsyncData('loginUser', () => $fetch('/api/auth/login', {
    method: 'POST',
    body: body
  }))

  return req.data
}

onMounted(() => {
  useThemeColor(appStore.currentEtablissement.theme.light);
})

</script>

<style scoped>
  .login {
    margin-top: 10rem;
  }
  .card {
    width: 35%;
    font-family: Inter, sans-serif;
  }
  span, .year {
    font-size: 13px;
  }
  button {
    font-size: 1rem;
    font-family: Inter, sans-serif;
  }
  input {
    /*float: left;*/
    font-family: Inter, sans-serif;
    position: relative;
    width: 100%;
    padding: 5px 4%;
    border: 1px solid #AAA;
    background-color: #EEE;
    /*height: 30px;*/
    font-size: 1em;
    color: #333;
    border-radius: 8px;
  }
  .logo {
    width: 35%;
  }
  .btn-linear {
    background: var(--btnLinear) !important;
    color: white !important;
  }
  .error {
    color: red;
    text-align: center;
  }

  .logoAutoPP{
    max-width: 80px;
    margin-bottom: 2px;
  }
  @media screen and (max-width: 768px) {
    .card {
      width: unset;
    }
    .login {
      margin: 5rem 1rem;
    }
    .formLastInfo {
      flex-direction: column;
      row-gap: 1rem;
      margin-top: 2rem !important;
    }
  }
</style>